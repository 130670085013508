// @import url('https://fonts.googleapis.com/css?family=Roboto');
// @import url('https://fonts.googleapis.com/css?family=Raleway');
// @use "sass:math";
@import "./variables.scss";
/* You can add global styles to this file, and also import other style files */

// @import url('https://fonts.googleapis.com/css?family=Work+Sans:600');
// @import url('https://fonts.googleapis.com/css?family=Lato');

@font-face {
  font-family: 'Metropolis-Regular';
  src: url('./assets/fonts/metropolis.regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis-Light';
  src: url('./assets/fonts/metropolis.light.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Helvetica.woff') format('woff');
}

body {
  overflow: hidden;
}

* {
  font-family:
    -apple-system,       /* iOS, macOS */
    BlinkMacSystemFont,  /* macOS in Chrome */
    // "Segoe UI",          /* Windows */
    Roboto,              /* Android */
    "Helvetica",
    sans-serif;
}


h1, h2, h3, h4, h5, h5, label, text {
  word-break: break-word;
}

.leaflet-popup {
  pointer-events: auto; /* Ensure popups are interactive */
}
//big blue button with filling
.btn-spaceshout {
  font-family: $main-font;
  color: white;
  background-color: $space-blue;

  &:hover {
    background-color: $space-blue--hover;
    cursor: pointer;
  }

  &:disabled {
    opacity: .65;
  }

  &--smaller {
    font-size: 0.8em;
  }
}

//big blue button with no filling
.btn-spaceshout-outline {
  color: $space-blue;
  background-color: transparent;
  border: 1px transparent;
  padding: 10px !important;

  &:hover {
    color: $space-blue--hover;
    border: 1px solid $space-blue;
    cursor: pointer;
  }

  &.btn:disabled {
    cursor: not-allowed;
  }

  //version for cancel/remove buttons
  &--bad {
    color: $space-tip--bad;

    &:hover {
      border: 1px solid $space-tip--bad;
      color: $space-tip--bad;

    }
  }

  //added to spans inside buttons
  &--text {
    padding: 8px;
  }

  //version of a button adjusted just for photo icon with no text
  &--add-photo {
    padding: 12px !important;
    margin: 0;
  }
}

/* start of spinner */

@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.welcome-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* end of spinner */
/*  */

.lightboxOverlay {
  height: 100% !important;
}

/* HASHTAGS */

.hashtag,
.email,
.url {
  color: #1976d2;
  cursor: pointer;
}

.hashtag:hover,
.email:hover,
.url:hover {
  color: #619ad3;
}

.hashtag-edit {
  background-color: #3fb4de;
}

.hashtag-edit-dark {
  background-color: #3fb4de;
}

@media (max-width: 920px) {
  .hideElement {
    display: none;
    visibility: hidden;
  }
}

/* USER */

.username {
  color: #aaa9a9;
  font-weight: bold;
  padding-left: 15px;
}

/* BUTTONS */

.btn-details {
  border-radius: .25rem !important;
  margin: 5px 10px 3px -5px;
  height: 30px;
  vertical-align: middle;
  padding: 7px 12px 7px 12px;
  font-size: 0.8em;
  outline: none;
  float: right;
  cursor: pointer;
}

.btn-modal {
  border-radius: .25rem !important;
  margin: 5px 10px 3px -5px;
  height: 30px;
  vertical-align: middle;
  padding: 7px 12px 7px 12px;
  font-size: 0.8em;
  outline: none;
  float: right;
  cursor: pointer;
}

.button-modal--upload {
  display: inline-block;
  position: relative;
  font-size: 14px;
  background-color: #3fb4de;
  color: #fff;
  padding: 0px 0;
  border: 1px solid #3fb4de;
  border-radius: 4px;
  width: 60px;
  height: 25px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

ng-select.ngSelectSearch {
  height: 38px;
  background-color: white;
  font-size: 1.5em;
  font-weight: lighter;
}

ng-select.ngSelectSearch>div {
  height: 38px !important;
  border: 1px solid #fcfcfc !important;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

ng-select.ngSelectSearch>div>div {
  height: 38px !important;
}

ng-select.ngSelectSearch>div>div>div {
  padding: 3px 10px 0 10px !important;
}


.disabled-content {
  cursor: not-allowed;
}

.disabled-content:hover {
  background-color: $space-blue;
}

.element--grey-out {
  color: gray;
}

/* START - map popup */

.leaflet-popup .leaflet-popup-content {
  margin: 0 !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  width: auto !important;
  border-radius: 6px;
}

.leaflet-popup-tip {
  display: none;
}

.leaflet-popup-content-wrapper {
  position: relative;
  top: 1px;
  animation: popupBounce 1000ms linear both;
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.leaflet-popup .leaflet-popup-content p {
  margin: 5px 0 5px 0 !important;
}

.leaflet-popup img {
  background-image: url("/assets/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  border-radius: 2px;
  max-height: 80px;
  overflow: visible;
}

.leaflet-container a.leaflet-popup-close-button {
  top: 1px !important;
  width: 16px !important;
  height: 15px !important;
  padding: 0 !important;
  border-bottom-left-radius: 11px;
  font-size: 0.8em !important;
  opacity: 0.5;
  animation: popupBounce 1000ms linear both;
}

.leaflet-popup img:hover,
.leaflet-popup p:hover {
  cursor: pointer;
}

.popup {
  &__image-popup {
    >.leaflet-popup-content-wrapper {
      background: transparent;
      padding: 0;
    }

    a.leaflet-popup-close-button {
      background-color: #fffffffc !important;
    }
  }

  &__selected {
    > .leaflet-popup-content-wrapper {
      .leaflet-popup-content {
        border: 2px solid $red2 !important;
      }
    }
  }

  &__text-popup {
    margin-bottom: 30px !important;
  }
}

/* START - map sidebar */
.modal {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.no-focus {
  outline: none !important;
  box-shadow: none !important;
}

.no-border {
  border: none !important;
}

.hidden-element {
  display: none;
  visibility: hiden;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}


@keyframes popupBounce {
  0% {
    transform: matrix3d(0.7, 0, 0, 0, 0, 0.7, 0, 0, 0, 0, 1, 0, 0, -35, 0, 1);
  }

  3.4% {
    transform: matrix3d(0.795, 0, 0, 0, 0, 0.822, 0, 0, 0, 0, 1, 0, 0, -24.073, 0, 1);
  }

  4.3% {
    transform: matrix3d(0.823, 0, 0, 0, 0, 0.862, 0, 0, 0, 0, 1, 0, 0, -20.935, 0, 1);
  }

  4.7% {
    transform: matrix3d(0.835, 0, 0, 0, 0, 0.88, 0, 0, 0, 0, 1, 0, 0, -19.515, 0, 1);
  }

  6.81% {
    transform: matrix3d(0.898, 0, 0, 0, 0, 0.968, 0, 0, 0, 0, 1, 0, 0, -12.074, 0, 1);
  }

  8.61% {
    transform: matrix3d(0.946, 0, 0, 0, 0, 1.029, 0, 0, 0, 0, 1, 0, 0, -6.247, 0, 1);
  }

  9.41% {
    transform: matrix3d(0.965, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, -3.978, 0, 1);
  }

  10.21% {
    transform: matrix3d(0.982, 0, 0, 0, 0, 1.068, 0, 0, 0, 0, 1, 0, 0, -1.956, 0, 1);
  }

  12.91% {
    transform: matrix3d(1.028, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 2.871, 0, 1);
  }

  13.61% {
    transform: matrix3d(1.037, 0, 0, 0, 0, 1.1, 0, 0, 0, 0, 1, 0, 0, 3.629, 0, 1);
  }

  14.11% {
    transform: matrix3d(1.042, 0, 0, 0, 0, 1.099, 0, 0, 0, 0, 1, 0, 0, 4.057, 0, 1);
  }

  17.22% {
    transform: matrix3d(1.062, 0, 0, 0, 0, 1.075, 0, 0, 0, 0, 1, 0, 0, 5.019, 0, 1);
  }

  17.52% {
    transform: matrix3d(1.062, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 4.993, 0, 1);
  }

  18.72% {
    transform: matrix3d(1.064, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 4.753, 0, 1);
  }

  21.32% {
    transform: matrix3d(1.059, 0, 0, 0, 0, 1.021, 0, 0, 0, 0, 1, 0, 0, 3.772, 0, 1);
  }

  24.32% {
    transform: matrix3d(1.045, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 2.441, 0, 1);
  }

  25.23% {
    transform: matrix3d(1.04, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 2.07, 0, 1);
  }

  28.33% {
    transform: matrix3d(1.023, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 1, 0, 1);
  }

  29.03% {
    transform: matrix3d(1.019, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0.807, 0, 1);
  }

  29.93% {
    transform: matrix3d(1.014, 0, 0, 0, 0, 0.97, 0, 0, 0, 0, 1, 0, 0, 0.586, 0, 1);
  }

  35.54% {
    transform: matrix3d(0.994, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, -0.2, 0, 1);
  }

  36.74% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.994, 0, 0, 0, 0, 1, 0, 0, -0.262, 0, 1);
  }

  39.44% {
    transform: matrix3d(0.989, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, -0.312, 0, 1);
  }

  41.04% {
    transform: matrix3d(0.988, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, -0.301, 0, 1);
  }

  44.44% {
    transform: matrix3d(0.99, 0, 0, 0, 0, 1.01, 0, 0, 0, 0, 1, 0, 0, -0.225, 0, 1);
  }

  52.15% {
    transform: matrix3d(0.997, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, -0.04, 0, 1);
  }

  59.86% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0.019, 0, 1);
  }

  61.66% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 0.997, 0, 0, 0, 0, 1, 0, 0, 0.021, 0, 1);
  }

  63.26% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 0.998, 0, 0, 0, 0, 1, 0, 0, 0.02, 0, 1);
  }

  75.28% {
    transform: matrix3d(1, 0, 0, 0, 0, 1.001, 0, 0, 0, 0, 1, 0, 0, 0.002, 0, 1);
  }

  83.98% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1);
  }

  85.49% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1);
  }

  90.69% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes like {
  0% {
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  3.4% {
    transform: matrix3d(0.658, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  4.7% {
    transform: matrix3d(0.725, 0, 0, 0, 0, 0.8, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  6.81% {
    transform: matrix3d(0.83, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  9.41% {
    transform: matrix3d(0.942, 0, 0, 0, 0, 1.084, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  10.21% {
    transform: matrix3d(0.971, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  13.61% {
    transform: matrix3d(1.062, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  14.11% {
    transform: matrix3d(1.07, 0, 0, 0, 0, 1.165, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  17.52% {
    transform: matrix3d(1.104, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  18.72% {
    transform: matrix3d(1.106, 0, 0, 0, 0, 1.094, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  21.32% {
    transform: matrix3d(1.098, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  24.32% {
    transform: matrix3d(1.075, 0, 0, 0, 0, 0.98, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  25.23% {
    transform: matrix3d(1.067, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  29.03% {
    transform: matrix3d(1.031, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  29.93% {
    transform: matrix3d(1.024, 0, 0, 0, 0, 0.949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  35.54% {
    transform: matrix3d(0.99, 0, 0, 0, 0, 0.981, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  36.74% {
    transform: matrix3d(0.986, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  41.04% {
    transform: matrix3d(0.98, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  44.44% {
    transform: matrix3d(0.983, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  52.15% {
    transform: matrix3d(0.996, 0, 0, 0, 0, 1.003, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  59.86% {
    transform: matrix3d(1.003, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  63.26% {
    transform: matrix3d(1.004, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  75.28% {
    transform: matrix3d(1.001, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  90.69% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}


.modal-dialog {
  height: calc(100%);
}


// pulsating icon
.gps_ring {
  border: 3px solid #1976d2;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  height: 30px;
  width: 30px;
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  /*opacity: 0.0*/
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

// hide captcha badge as long as below is in the footer of website
// This site is protected by reCAPTCHA and the Google
//<a href="https://policies.google.com/privacy">Privacy Policy</a> and
//<a href="https://policies.google.com/terms">Terms of Service</a> apply.
.grecaptcha-badge {
  visibility: hidden;
}

#draw-panel-help,
.leaflet-area-selector-control {
  display: none;
}

div.leaflet-bottom.leaflet-left {
  transform: scale(1.1);
}

.cursor-pointer {
  cursor: pointer;

  & * {
    cursor: pointer;
  }
}

.cursor-text {
  cursor: text;

  & * {
    cursor: text;
  }
}

.pointer-events-none {
  cursor: not-allowed;
  & * {
    cursor: not-allowed !important;
  }
  pointer-events: none;
}

.z-index-1 {
  z-index: 1 !important;
}

.display-none {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}